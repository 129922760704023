<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
  >
    <g
        id="Icona_liquidita"
        transform="translate(-716 -1014)"
    >
      <rect
          id="Icon_fin_spacer"
          width="48"
          height="48"
          transform="translate(716 1014)"
          fill="none"
      />
      <g
          id="Icona_cofidis_nuova_liquidita"
          transform="translate(716.09 1013.78)"
      >
        <path
            id="Path_8123"
            data-name="Path 8123"
            d="M45.76,20.45l-1.4,1.4A21.762,21.762,0,1,0,33.42,42.96l.27-.15-.93-1.63-.27.15a19.91,19.91,0,1,1,9.96-19.75l-1.14-1.14-1.33,1.33,3.55,3.55,3.55-3.55Z"
            fill="#252525"
        />
        <path
            id="Path_8124"
            data-name="Path 8124"
            d="M19.55,13.85c4.46-1.66,8.55-.76,12.1,2.66l.33.32.18-.42c.19-.45.37-.9.55-1.35l.08-.2-.15-.15a12.931,12.931,0,0,0-6.67-3.42c-.34-.06-.68-.11-1.02-.16l-.47-.07H22.8l-.58.08q-.585.075-1.17.18a13.014,13.014,0,0,0-10.07,9.52l-.02.08H8.15l-.08.2c-.18.45-.38.9-.57,1.35l-.19.44h3.28v2.4H8.96c-.08,0-.17-.01-.29-.02-.07,0-.13-.01-.19-.01l-.16-.16-.22.28a1.5,1.5,0,0,0-.17.39,2,2,0,0,1-.1.26L7.29,27.3h3.65l.02.08a12.493,12.493,0,0,0,1.27,3.15,12.941,12.941,0,0,0,9.39,6.48c.24.04.49.07.73.1l.43.06h1.64l.78-.1a12.773,12.773,0,0,0,6.54-2.79l.11-.09v-.15c.01-.6.01-1.2.01-1.8v-.71l-.51.47a10.81,10.81,0,0,1-11.39,2.54,10.8,10.8,0,0,1-6.94-7.1l-.05-.14H27.61l.08-.18c.19-.45.38-.9.57-1.37l.18-.43H12.57V22.93h16.1c.08,0,.17.01.31.02l.37.03.1-.1a1.067,1.067,0,0,0,.2-.43,2.34,2.34,0,0,1,.09-.24l.53-1.27H12.98l.05-.14A10.775,10.775,0,0,1,19.55,13.85Z"
            fill="#252525"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
